


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import Sidebar from '../sidebar/Sidebar'; // Import Sidebar component
import Loader from '../../UserSide/Components/LoaderComponent'; // Import Loader component
import { ToastContainer, toast } from 'react-toastify'; // Import ToastContainer for notifications
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify CSS
import { BASE_URL } from '../../Services';
import { useUser } from '../../Services/UserContext';

const WinnerDetails = () => {
    const { id } = useParams();
    const [winner, setWinner] = useState(null);
    const [reward, setReward] = useState(null); 
    const [loading, setLoading] = useState(true);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const { userData } = useUser();

    useEffect(() => {
        const fetchWinnerAndReward = async () => {
            try {
                const winnerResponse = await axios.get(`${BASE_URL}/winners/${id}`, {
                    headers: {
                        Authorization: `Bearer ${userData?.token}`
                    }
                });
                setWinner(winnerResponse.data);

                if (winnerResponse.data.reward_id) {
                    const rewardResponse = await axios.get(`${BASE_URL}/rewards/${winnerResponse.data.reward_id}`, {
                        headers: {
                            Authorization: `Bearer ${userData?.token}`
                        }
                    });
                    setReward(rewardResponse.data);
                }
            } catch (error) {
                console.error('Error fetching winner details:', error.response ? error.response.data : error.message);
                toast.error('Error fetching winner details.');
            } finally {
                setLoading(false);
            }
        };

        fetchWinnerAndReward();
    }, [id]);

    const handleSidebarToggle = (isOpen) => {
        setIsSidebarOpen(isOpen);
    };

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <Loader /> 
            </div>
        );
    }

    if (!winner) {
        return <p className="text-center text-danger">No winner details found</p>;
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <Sidebar onToggleSidebar={handleSidebarToggle} />
                <div className={`col ${isSidebarOpen ? 'col-md-10' : 'col-md-12 mt-3'} ms-auto`}>
                    <div className="dashboard-content p-4">
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <h1 className="fs-3 fw-bold text-dark shadow p-3 mb-2 bg-light rounded">
                                Winner Details
                            </h1>
                            <Link to="/dashboard/winners" className="btn btn-secondary shadow-sm">
                                <i className="bi bi-arrow-left me-2"></i> Back to Winners List
                            </Link>
                        </div>

                        <div className="card shadow-lg border-0 rounded">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-4 d-flex justify-content-center">
                                        <img
                                            src={winner.image}
                                            alt={winner.name}
                                            className="img-fluid rounded shadow"
                                            style={{ maxWidth: '100%', height: 'auto' }} // Adjust image size
                                        />
                                    </div>
                                    <div className="col-md-8">
                                        <h2 className="text-primary mb-3">{winner.name}</h2>
                                        <div className="mb-3">
                                            <p><strong>Participant:</strong> 
                                                {winner.participant && winner.participant.invoice && winner.participant.invoice.user 
                                                    ? winner.participant.invoice.user.name 
                                                    : 'N/A'}
                                            </p>
                                            <p><strong>Ticket:</strong> 
                                                {winner.participant && winner.participant.invoice && winner.participant.invoice.ticket_id 
                                                    ? winner.participant.invoice.ticket_id 
                                                    : 'N/A'}
                                            </p>
                                            <p><strong>Draw Date:</strong> {winner.draw_date}</p>
                                        </div>
                                        {/* Display Reward Details */}
                                        <div className="mt-3">
                                            <h3>Reward Details</h3>
                                            <p><strong>Reward Name:</strong> {reward ? reward.name : 'N/A'}</p>
                                            <p><strong>Reward Description:</strong> {reward ? reward.description : 'N/A'}</p>
                                            {reward && reward.image && (
                                                <div className="mt-3">
                                                    <p><strong>Reward Image:</strong></p>
                                                    <img
                                                        src={reward.image}
                                                        alt={reward.name}
                                                        className="img-fluid rounded shadow"
                                                        style={{ maxWidth: '150px', height: 'auto' }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ToastContainer /> {/* Add ToastContainer to show toast notifications */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WinnerDetails;
