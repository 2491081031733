import React from 'react';
const truncateText = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  };
export const CartComponent = ({ item, increaseQuantity, decreaseQuantity, deleteItem }) => {
    return (
<div className='container-fluid p-4 '>
<div style={{ borderRadius:'20px' }} className="row align-items-center mb-3 p-4 bg-white shadow mt-3"  key={item.item_id} data-product-id={item.item_id}>
            <div className="col-12 col-md-3 text-center mb-3 mb-md-0">
                <img
                    src={item.reward ? item.reward : item.image}
                    alt={item.name || 'Product Image'}
                    className="img-fluid rounded"
                    style={{ width: '100%', objectFit: 'cover' }}
                />
            </div>


            <div className="col-12 col-md-5 text-start text-md-left mb-3 mb-md-0 ">
                <p style={{fontSize: '14px'}} className="mb-0 d-none d-md-block">{item.description || 'No description available'}</p>
            </div>

            <p style={{fontSize: '14px'}} className="mb-0 d-md-none d-lg-none">{truncateText(item.description, 30) || 'No description available'}</p>

            <div className="col-12 col-md-4 d-flex justify-content-center justify-content-md-end align-items-center">
                <button 
                    type="button" 
                    className="btn btn-outline-secondary m-1 me-1" 
                    onClick={decreaseQuantity} 
                    disabled={item.quantity <= 1}
                >
                    <i className="bi bi-dash "></i>
                </button>

                <input 
                    type="text" 
                    id="txtAcrescimo" 
                    value={item.quantity} 
                    readOnly 
                    className="form-control d-inline-block text-center mx-2"
                    style={{ maxWidth: '40px',height:'40px',borderRadius:'10px', fontSize: '18px'}}
                />

                <button 
                    type="button" 
                    className="btn btn-outline-secondary m-1  me-2" 
                    onClick={increaseQuantity}
                >
                    <i className="bi bi-plus"></i>
                </button>

                <button 
                    type="button" 
                    className="btn btn-danger m-1 ms-2" 
                    onClick={deleteItem}
                >
                    <i className="bi bi-trash"></i>
                </button>
            </div>
        </div>


        </div>
    );
};
