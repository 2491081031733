import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Sidebar.css';
import Header from './Header';

const screenList = [
    {title:"Users",nav:"/dashboard",icon:"bi-house-door"},
    {title:"Rewards",nav:"/dashboard/rewards",icon:"bi bi-trophy"},
    {title:"Products",nav:"/dashboard/products",icon:"bi bi-box"},
    {title:"Questions",nav:"/dashboard/questions",icon:"bi bi-question-circle"},
    {title:"FAQs",nav:"/dashboard/faqs",icon:"bi bi-question"},
    {title:"About Us",nav:"/dashboard/about-us",icon:"bi bi-info-circle"},
    {title:"Blogs",nav:"/dashboard/blogs",icon:"bi bi-journal-text"},
    {title:"Videos",nav:"/dashboard/videos",icon:"bi bi-film"},
    {title:"Banners",nav:"/dashboard/banners",icon:"bi bi-image"},
    {title:"Invoices",nav:"/dashboard/invoices",icon:"bi bi-receipt"},
    {title:"Participants",nav:"/dashboard/participants",icon:"bi bi-people"},
    {title:"Contacts",nav:"/dashboard/contacts",icon:"bi bi-telephone"},
    {title:"Winners",nav:"/dashboard/winners",icon:"bi bi-trophy-fill"},
];

function Sidebar({ onToggleSidebar }) {
    const [isOpen, setIsOpen] = useState(true);
    const [selected, setSelected] = useState("");
    const location = useLocation();

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
        if (onToggleSidebar) {
            onToggleSidebar(!isOpen);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 750) {
                setIsOpen(false);
            } else {
                setIsOpen(true);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const currentItem = screenList.find(item => item.nav === location.pathname);
        if (currentItem) {
            setSelected(currentItem.title);
        }
    }, [location.pathname]);

    return (
        <div className="sidebar-container">
            <div style={{ flexDirection: "row" }}>
                <div className={`sidebar ${isOpen ? 'open' : 'closed'}`}>
                    <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between" }}>
                        <img
                            style={{ width: '150px', height: 'auto',margin:10 }}
                            className="logo"
                            src={require('../../UserSide/images/logo.png')}
                            alt="Logo"
                        />
                        <button className="sidebar-toggle" onClick={toggleSidebar}>
                            {isOpen ? <i className="bi bi-chevron-left"></i> : <i className="bi bi-chevron-right"></i>}
                        </button>
                    </div>

                    {!isOpen ? (
                        <button className="sidebar-toggle-closed" onClick={toggleSidebar}>
                            {isOpen ? <i className="bi bi-chevron-left"></i> : <i className="bi bi-chevron-right"></i>}
                        </button>
                    ) : <div></div>}

                    <ul className="sidebar-menu">
                        {screenList.map((item, index) => (
                            <SideBarElement
                                key={index}
                                item={item}
                                selected={selected}
                                onClick={setSelected}
                            />
                        ))}
                    </ul>
                </div>
                <div>
                    <Header screenName={selected} />
                </div>
            </div>
        </div>
    );
}

export default Sidebar;

const SideBarElement = ({ item, selected, onClick }) => {
    return (
        <li onClick={() => onClick(item.title)}>
            <Link
                className={selected === item.title ? 'sidebar-selected' : 'sidebar-link'}
                to={item.nav}
            >
                <i className={item.icon}></i> {item.title}
            </Link>
        </li>
    );
};
