
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../../Components/HeaderComponent';
import Footer from '../../Components/FooterCompnent';
import Loader from '../../Components/LoaderComponent';
import { BASE_URL } from '../../../Services';
import { useUser } from '../../../Services/UserContext';
import './recentvideo.css' ;


const extractYouTubeID = (url) => {
  const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = url.match(regex);
  return match ? match[1] : null;
};

const RecentLuckyDraws = () => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const { userData } = useUser()

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/videos`, {
          headers: {
            Authorization: `Bearer ${userData?.token}`,
          },
        });
        if (response.data.status === 200 && Array.isArray(response.data.videos)) {
          setVideos(response.data.videos);
        } else {
          console.error('Unexpected response format:', response.data);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching videos:', error);
        setLoading(false);
      }
    };
    fetchVideos();
  }, []);

  return (
    <div className='App'>
      <Header />
      <section className="home-header inner-page">
        <div className="container-fluid">
          <h3 className="title-style mb-2 text-center" style={{ fontWeight: "600",fontSize: "30px" }}>Recent Lucky Draws</h3>
          <div className="w3l-bottom-grids-6 service-w3l-bg py-2 winners-list" id="winners">
            <div className="container-fluid col-lg-10">
              <div className="pt-md-4 justify-content-center">
                <div className="row">
                  {loading ? (
                    <Loader />
                  ) : (
                    videos.length > 0 ? (
                      videos.map((video, index) => {
                        const youTubeID = video.video_url ? extractYouTubeID(video.video_url) : null;
                        
                        return (
                          <div className="mb-3 col-lg-4 col-md-6 col-sm-12" key={index}>
                            <div className="card shadow-sm mb-4" style={{ borderRadius: '15px', overflow: 'hidden' }}>
                              {youTubeID ? (
                                <div className="video-embed">
                                  <iframe
                                    width="100%"
                                    height="200"
                                    src={`https://www.youtube.com/embed/${youTubeID}`}
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                  ></iframe>
                                </div>
                              ) : video.video ? (
                                <video
                                  className="w-100"
                                  controls
                                  style={{ height: '200px' }}
                                  poster={video.thumbnail}
                                >
                                  <source src={video.video} type="video/mp4" />
                                  Your browser does not support the video tag.
                                </video>
                              ) : (
                                <div className="text-center">No video available</div>
                              )}
                              <div className="card-body text-center recent-card-body" style={{ padding: '1rem' }}>
                                <h5 style={{fontSize:'1.2rem'}} className="card-title">{video.title}</h5>
                                <p  className="username-text mb-2"><h5 className='text-center font-weight-bold'>{video.user ? video.user.name : 'N/A'}</h5></p>
                                <p className="card-text"><strong>Reward Name:</strong> {video.reward ? video.reward.name : 'N/A'}</p>
                                <p className="card-text"><strong className='text-muted'>Announced on:</strong> {video.date_announced ? new Date(video.date_announced).toLocaleDateString() : 'N/A'}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="col-12 text-center">No videos available</div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default RecentLuckyDraws;
