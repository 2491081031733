import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; 
import Header from '../UserSide/Components/HeaderComponent';
import Footer from '../UserSide/Components/FooterCompnent';
import { BASE_URL } from '../Services';

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [step, setStep] = useState('request'); 
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate(); 

    const handleRequest = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${BASE_URL}/forgot-password`, { email });
            setMessage(response.data.message);
            setError('');
            setStep('verify');
        } catch (error) {
            setError('Failed to send OTP.');
            setMessage('');
        }
    };

   
    const handleVerifyOtp = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${BASE_URL}/verify-otp-reset`, { email, otp });
    
            if (response.data.status === 200) {
                setMessage(response.data.message);
                setError('');
    
                const userResponse = await axios.post(`${BASE_URL}/get-user-details`, { email });
    
                navigate('/reset-password', { state: { user: userResponse.data.user } });
            } else {
                setError('Invalid OTP.');
                setMessage('');
            }
        } catch (error) {
            setError('Invalid OTP.');
            setMessage('');
        }
    };
    return (
        <div className="App">
            <Header />
            <div className="container mt-5">
                <div className="row justify-content-center mt-5">
                    <div className="col-md-6 col-lg-6 mt-5">
                        <div className="card p-4 shadow-lg rounded">
                            <h2 className="mb-4 text-center">{step === 'request' ? 'Forgot Password' : 'Verify OTP'}</h2>
                            {step === 'request' ? (
                                <form onSubmit={handleRequest}>
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">Enter your email</label>
                                        <input
                                            type="email"
                                            id="email"
                                            className="form-control"
                                            placeholder="Enter your email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <button type="submit" className="btn btn-primary w-100">Send OTP</button>
                                </form>
                            ) : (
                                <form onSubmit={handleVerifyOtp}>
                                    <div className="mb-3">
                                        <label htmlFor="otp" className="form-label">Enter OTP</label>
                                        <input
                                            type="text"
                                            id="otp"
                                            className="form-control"
                                            placeholder="Enter OTP"
                                            value={otp}
                                            onChange={(e) => setOtp(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <button type="submit" className="btn btn-primary w-100">Verify OTP</button>
                                </form>
                            )}
                            {message && <p className="mt-3 text-success text-center">{message}</p>}
                            {error && <p className="mt-3 text-danger text-center">{error}</p>}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default ForgotPassword;
