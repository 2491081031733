import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../Services';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../sidebar/Sidebar';
import Loader from '../../UserSide/Components/LoaderComponent';

const WinnerEdit = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [participantId, setParticipantId] = useState('');
    const [drawDate, setDrawDate] = useState('');
    const [image, setImage] = useState(null);
    const [currentImage, setCurrentImage] = useState('');
    const [rewardId, setRewardId] = useState('');
    const [ticketIds, setTicketIds] = useState([]); // State for ticket IDs
    const [selectedTicketId, setSelectedTicketId] = useState(''); // State for selected ticket ID
    const [participants, setParticipants] = useState([]);
    const [rewards, setRewards] = useState([]);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchWinner = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${BASE_URL}/winners/${id}`);
                const { name, participant_id, draw_date, image, reward_id, ticket_id } = response.data;
                setName(name);
                setParticipantId(participant_id);
                setDrawDate(draw_date);
                setCurrentImage(image);
                setRewardId(reward_id);
                setSelectedTicketId(ticket_id || ''); 
            } catch (error) {
                console.error('Error fetching winner:', error);
                toast.error('Failed to fetch winner data.');
            } finally {
                setLoading(false);
            }
        };

        const fetchParticipants = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/participants`);
                setParticipants(response.data);
            } catch (error) {
                console.error('Error fetching participants:', error);
                toast.error('Failed to fetch participants.');
            }
        };

        const fetchRewards = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/new-rewards`);
                setRewards(response.data);
            } catch (error) {
                console.error('Error fetching rewards:', error);
                toast.error('Failed to fetch rewards.');
            }
        };

        fetchWinner();
        fetchParticipants();
        fetchRewards();
    }, [id]);

    useEffect(() => {
        if (participantId) {
            const fetchTicketIds = async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/participants/${participantId}`);
                    const participant = response.data;
                    if (participant.invoice && participant.invoice.ticket_id) {
                        setTicketIds(participant.invoice.ticket_id);
                    } else {
                        setTicketIds([]);
                    }
                } catch (error) {
                    console.error('Error fetching ticket IDs:', error);
                }
            };

            fetchTicketIds();
        } else {
            setTicketIds([]);
        }
    }, [participantId]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const formData = new FormData();
        formData.append('_method', 'PUT');
        formData.append('name', name);
        formData.append('participant_id', participantId);
        formData.append('draw_date', drawDate);
        formData.append('reward_id', rewardId);
        formData.append('ticket_id', selectedTicketId); // Include ticket_id in formData
        if (image) formData.append('image', image);

        try {
            await axios.post(`${BASE_URL}/winners/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            toast.success('Winner updated successfully!');
            navigate('/dashboard/winners');
        } catch (error) {
            console.error('Error updating winner:', error);
            toast.error('Failed to update winner data.');
        } finally {
            setLoading(false);
        }
    };

    const handleSidebarToggle = (isOpen) => {
        setIsSidebarOpen(isOpen);
    };

    if (loading) {
        return (
            <div className="container mt-5 d-flex justify-content-center">
                <Loader />
            </div>
        );
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <Sidebar onToggleSidebar={handleSidebarToggle} />
                <div className={`col ${isSidebarOpen ? 'col-md-10' : 'col-md-12 mt-3'} ms-auto`}>
                    <div className="container-fluid mt-4 p-5 bg-light rounded shadow-sm">
                        <h1 className="mb-4">Edit Winner</h1>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="participant_id" className="form-label">Participant</label>
                                <select
                                    className="form-control"
                                    id="participant_id"
                                    value={participantId}
                                    onChange={(e) => setParticipantId(e.target.value)}
                                    required
                                >
                                    <option value="">Select Participant</option>
                                    {participants.map(participant => (
                                        <option key={participant.id} value={participant.id}>
                                            {participant.invoice && participant.invoice.user ? participant.invoice.user.name : 'N/A'}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="reward_id" className="form-label">Reward</label>
                                <select
                                    className="form-control"
                                    id="reward_id"
                                    value={rewardId}
                                    onChange={(e) => setRewardId(e.target.value)}
                                    required
                                >
                                    <option value="">Select Reward</option>
                                    {rewards.map(reward => (
                                        <option key={reward.id} value={reward.id}>
                                            {reward.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="draw_date" className="form-label">Draw Date</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    id="draw_date"
                                    value={drawDate}
                                    onChange={(e) => setDrawDate(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="ticket_id" className="form-label">Ticket ID</label>
                                <select
                                    className="form-control"
                                    id="ticket_id"
                                    value={selectedTicketId}
                                    onChange={(e) => setSelectedTicketId(e.target.value)}
                                    required
                                >
                                    <option value="">Select Ticket ID</option>
                                    {ticketIds.map(ticketId => (
                                        <option key={ticketId} value={ticketId}>
                                            {ticketId}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="image" className="form-label">Image</label>
                                {currentImage && (
                                    <div className="mb-3">
                                    <img
                                        src={currentImage}
                                        alt="Current"
                                        style={{ width: '150px', borderRadius: '10px' }}
                                    />
                                    </div>
                                )}
                                <input
                                    type="file"
                                    className="form-control"
                                    id="image"
                                    onChange={(e) => setImage(e.target.files[0])}
                                />
                            </div>
                            <button type="submit" className="btn btn-primary">Update</button>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default WinnerEdit;
