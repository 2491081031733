

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../Services';
import Sidebar from '../sidebar/Sidebar'; 

import Loader from '../../UserSide/Components/LoaderComponent';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const WinnerCreate = () => {
    const [name, setName] = useState('');
    const [participantId, setParticipantId] = useState('');
    const [drawDate, setDrawDate] = useState('');
    const [image, setImage] = useState(null);
    const [participants, setParticipants] = useState([]);
    const [rewards, setRewards] = useState([]);
    const [rewardId, setRewardId] = useState('');
    const [ticketIds, setTicketIds] = useState([]); // State for ticket IDs
    const [selectedTicketId, setSelectedTicketId] = useState(''); // State for selected ticket ID
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchParticipants = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/participants`);
                setParticipants(response.data);
            } catch (error) {
                console.error('Error fetching participants:', error);
            }
        };

        const fetchRewards = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/new-rewards`);
                setRewards(response.data);
            } catch (error) {
                console.error('Error fetching rewards:', error);
            }
        };

        fetchParticipants();
        fetchRewards();
    }, []);

    useEffect(() => {
        if (participantId) {
            const fetchTicketIds = async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/participants/${participantId}`);
                    const participant = response.data;
                    if (participant.invoice && participant.invoice.ticket_id) {
                        setTicketIds(participant.invoice.ticket_id);
                    } else {
                        setTicketIds([]);
                    }
                } catch (error) {
                    console.error('Error fetching ticket IDs:', error);
                }
            };

            fetchTicketIds();
        } else {
            setTicketIds([]);
        }
    }, [participantId]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData();
        formData.append('name', name);
        formData.append('participant_id', participantId);
        formData.append('reward_id', rewardId);
        formData.append('draw_date', drawDate);
        formData.append('image', image);
        formData.append('ticket_id', selectedTicketId); // Add ticket_id to formData

        try {
            await axios.post(`${BASE_URL}/winners`, formData);
            toast.success('Winner created successfully!');
            navigate('/dashboard/winners');
        } catch (error) {
            console.error('Error creating winner:', error);
            toast.error('Error creating winner');
        } finally {
            setLoading(false);
        }
    };

    const handleSidebarToggle = (isOpen) => {
        setIsSidebarOpen(isOpen);
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <Sidebar onToggleSidebar={handleSidebarToggle} />
                <div className={`col ${isSidebarOpen ? 'col-md-10' : 'col-md-12 mt-3'} ms-auto`}>
                    <div className="container-fluid col-md-12 mt-4">
                        <div className="p-4 bg-light rounded shadow-sm">
                            {loading ? (
                                <div className="d-flex justify-content-center">
                                    <Loader />
                                </div>
                            ) : (
                                <div>
                                    <h1 className="mb-4 fs-4 fw-bold text-dark">Create Winner</h1>
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-3">
                                            <label htmlFor="name" className="form-label">Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="name"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="participantId" className="form-label">Participant</label>
                                            <select
                                                className="form-control"
                                                id="participantId"
                                                value={participantId}
                                                onChange={(e) => setParticipantId(e.target.value)}
                                                required
                                            >
                                                <option value="">Select Participant</option>
                                                {participants.map(participant => (
                                                    <option key={participant.id} value={participant.id}>
                                                        {participant.invoice && participant.invoice.user ? participant.invoice.user.name : 'N/A'}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="rewardId" className="form-label">Reward</label>
                                            <select
                                                className="form-control"
                                                id="rewardId"
                                                value={rewardId}
                                                onChange={(e) => setRewardId(e.target.value)}
                                                required
                                            >
                                                <option value="">Select Reward</option>
                                                {rewards.map(reward => (
                                                    <option key={reward.id} value={reward.id}>
                                                        {reward.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="drawDate" className="form-label">Draw Date</label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                id="drawDate"
                                                value={drawDate}
                                                onChange={(e) => setDrawDate(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="ticketId" className="form-label">Ticket ID</label>
                                            <select
                                                className="form-control"
                                                id="ticketId"
                                                value={selectedTicketId}
                                                onChange={(e) => setSelectedTicketId(e.target.value)}
                                                required
                                            >
                                                <option value="">Select Ticket ID</option>
                                                {ticketIds.map(ticketId => (
                                                    <option key={ticketId} value={ticketId}>
                                                        {ticketId}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="image" className="form-label">Image</label>
                                            <input
                                                type="file"
                                                className="form-control"
                                                id="image"
                                                onChange={(e) => setImage(e.target.files[0])}
                                                required
                                            /> 
                                            <img style={{height:"120px",width:"150px" ,borderRadius:"10px"}} src={image ? URL.createObjectURL(image) : null} className="img-fluid mt-3" alt="" />
                                        </div>
                                        <button type="submit" className="btn btn-primary shadow-sm">
                                            Save
                                        </button>
                                    </form>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default WinnerCreate;
