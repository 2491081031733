import React, { useEffect, useState } from 'react';
import { useUser } from '../../../Services/UserContext';
import { BASE_URL } from '../../../Services';

import Header from '../../Components/HeaderComponent';
import Footer from '../../Components/FooterCompnent';
import axios from 'axios';
import "../../css/Styles.css";
import Loader from '../../Components/LoaderComponent';

import './aboutus.css'; 

const AboutUs = () => {
  const [aboutUsList, setAboutUsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const { userData } = useUser();

  useEffect(() => {
    const fetchAboutUsData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/about-us`, {
          headers: {
            Authorization: `Bearer ${userData?.token}`,
          },
        });

        if (response.data.status === 200 && Array.isArray(response.data.aboutUs)) {
          setAboutUsList(response.data.aboutUs);
        } else {
          console.error('Unexpected response format:', response.data);
        }
      } catch (error) {
        console.error('Error fetching About Us data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAboutUsData();
  }, [userData?.token]);

  return (
    <div className='App'>
      <Header />
      <div className="about-us-container " />
      <section className="home-header inner-page home-header-about-us ">
        <div className="container-fluid about-us-container-new col-lg-12">
          {loading ? (
            <Loader />
          ) : (
            aboutUsList.length > 0 ? (
              <div className="row">
                {aboutUsList.map((item, index) => (
                  <div className="col-md-12 about-us-col" key={index}>
                    <div className="card shadow-sm about-us-card">
                      <div className="card-body about-us-card-body">
                        <div className='row'>
                      
                      

                        <div className="col-12 d-lg-none d-md-none">
                            {item.about_image && (
                              <img
                                src={item.about_image}
                                alt={item.heading}
                                className=" about-us-image-mbl"
                              />
                            )}
                          </div>

                      
                      
                          <div className="col-md-7 text-end">
                            <h4 className="mb-2 about-us-heading">{item.heading}</h4>
                            <p className="about-us-text">{item.about_detail}</p>
                          </div>
                          <div className="col-md-5 text-end d-none d-lg-block d-md-block">
                            {item.about_image && (
                              <img
                                src={item.about_image}
                                alt={item.heading}
                                className="img-fluid about-us-image"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-center">No About Us data available.</p>
            )
          )}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default AboutUs;
